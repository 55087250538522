 <!--门禁模块--报表数据页-->
 <template>
  <div id="realdata">
    <div class="header">
      <my-headertitle>
        {{ $t("realdata.a2") }}
        <template v-slot:name>
          <a-radio-group
            v-model="Pageselection"
            button-style="solid"
            style="margin-right: 50px"
          >
            <a-radio-button :value="0">{{ $t("realdata.a3") }}</a-radio-button>
            <a-radio-button :value="1">{{ $t("realdata.a4") }}</a-radio-button>
            <a-radio-button :value="2">{{ $t("realdata.a5") }}</a-radio-button>
          </a-radio-group>
          <a-checkbox v-model="onlyCardHistory">{{
            $t("realdata.a6")
          }}</a-checkbox>
        </template>
      </my-headertitle>
    </div>
    <transition name="slide-fade">
      <div class="main" v-if="Pageselection == 0">
        <my-all :onlyCardHistory="onlyCardHistory"></my-all>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="main" v-if="Pageselection == 1">
        <my-door :onlyCardHistory="onlyCardHistory"></my-door>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="main" v-if="Pageselection == 2">
        <my-card :onlyCardHistory="onlyCardHistory"></my-card>
      </div>
    </transition>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import realdataAll from "./components/realdataAll";
import realdataDoor from "./components/realdataDoor";
import realdataCard from "./components/realdataCard";

export default {
  data() {
    return {
      Pageselection: 0,
      onlyCardHistory: true,
    };
  },
  components: {
    "my-headertitle": headerTitle,
    "my-all": realdataAll,
    "my-door": realdataDoor,
    "my-card": realdataCard,
  },
};
</script>

<style scoped>
#realdata {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.header {
  padding: 0px 20px;
}
.cut {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.main {
  width: 100%;
  height: calc(100% - 53px);
  padding: 0px 20px 10px 20px;
  position: absolute;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>