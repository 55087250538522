<!--门禁模块--报表数据页--卡-->
<template>
  <div id="card">
    <div class="header">
      <div>
        <a-radio-group v-model="Dateselection" button-style="solid">
          <a-radio-button :value="0">{{ $t("realdata.a7") }}</a-radio-button>
          <a-radio-button :value="1">{{ $t("realdata.a8") }}</a-radio-button>
        </a-radio-group>
      </div>
      <div class="header_left_bottom">
        <div class="header_left_bottom_date">
          <span>{{ $t("realdata.a9") }}</span>
          <a-date-picker v-model="date" />
          <span class="interval">～</span>
          <span>{{ date1 }}</span>
        </div>
        <div class="header_left_bottom_right">
          <transition name="slide-fade">
            <div class="file" v-if="Dateselection == 0">
              <span>{{ $t("realdata.a10") }}</span>
              <a-select v-model="fileValue" style="width: 70px">
                <a-select-option v-for="n in 5" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
          </transition>
          <transition name="slide-fade">
            <div class="date" v-if="Dateselection == 1">
              <span>{{ $t("realdata.a11") }}</span>
              <a-select v-model="day" style="width: 70px">
                <a-select-option v-for="n in days" :key="n" :value="n">
                  {{ n }}
                </a-select-option>
              </a-select>
            </div>
          </transition>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="" v-if="Dateselection == 0">
          <a-popover placement="bottom">
            <template slot="content">
              <p class="inquire_explain">
                {{ $t("realdata.a12") }}<br />
                {{ $t("realdata.a13") }}<br />
                {{ $t("realdata.a14") }}<br />
                {{ $t("realdata.a15") }}<br />
                {{ $t("realdata.a16") }}<br />
                {{ $t("realdata.a17") }}
              </p>
            </template>
            <a-tag color="blue"> {{ $t("realdata.a21") }} </a-tag>
          </a-popover>
        </div>
      </transition>
    </div>
    <div
      class="main"
      ref="main"
      v-loading="mainLoading"
      :element-loading-text="$t('realdata.a1')"
    >
      <div class="main_header">
        <div class="main_header_condition">
          <div>
            <span>{{ $t("realdata.a22") }}</span>
            <a-input
              v-model="personName"
              allow-clear
              :maxLength="30"
              :placeholder="$t('realdata.a23')"
              style="width: 160px"
              v-InputText
            />
          </div>
          <div>
            <span>{{ $t("realdata.a24") }}</span>
            <a-input
              v-model="cardFaceNo"
              allow-clear
              :maxLength="30"
              :placeholder="$t('realdata.a25')"
              style="width: 160px"
              v-Input-NumberText
            />
          </div>
          <div>
            <span>{{ $t("realdata.a26") }}</span>
            <a-input
              v-model="cardNo"
              allow-clear
              :maxLength="30"
              :placeholder="$t('realdata.a27')"
              style="width: 160px"
              v-Input-NumberText
            />
          </div>
          <div>
            <span>{{ $t("realdata.a28") }}</span>
            <a-input
              v-model="managementCode"
              allow-clear
              :maxLength="30"
              :placeholder="$t('realdata.a29')"
              style="width: 160px"
              v-Input-NumberText
            />
          </div>
        </div>
        <div>
          <a-button type="primary" @click="inquire">{{
            $t("realdata.a30")
          }}</a-button>
        </div>
      </div>
      <my-tabletitle>{{ $t("realdata.a31") }}</my-tabletitle>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :scroll="size"
        :pagination="pagination"
        :dataSource="tableData"
        :rowKey="(record) => record.cardId"
      >
      </a-table>
    </div>
    <div class="floor">
      <div class="floor_left">
        <div class="floor_title">
          <span>{{ $t("realdata.a18") }}</span>
        </div>
        <div class="floor_output" v-if="loading">
          <a-icon type="sync" spin />
          <span>{{ $t("realdata.a19") }}...</span>
        </div>
        <div class="floor_download" v-if="filendownload">
          <span>{{ filename }}</span>
          <a-icon type="download" @click="download" />
        </div>
      </div>
      <div>
        <a-button type="primary" @click="output" :disabled="disabled">{{
          $t("realdata.a20")
        }}</a-button>
      </div>
    </div>
  </div>
</template>


<script>
import tabletitle from "../../../components/Title/tabletitle";
import moment from "moment";
import { dataDownload, getAllUseCardList } from "../../../api/door";
import { tableSort } from "../../../utils/utils";

export default {
  props: {
    onlyCardHistory: {
      type: Boolean,
    },
  },
  data() {
    return {
      mainLoading: false,
      loading: false,
      filendownload: false,
      dataType: 2,
      Dateselection: 0,
      date: moment().subtract(1, "months"),
      date1: "",
      date2: "",
      fileValue: 1,
      days: 30,
      day: 1,
      filename: "",
      file: {},
      cardFaceNo: "",
      cardNo: "",
      managementCode: "",
      personName: "",
      tableData: [],
      size: { y: 240 },
      selectedRowKeys: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("realdata.a32")}`, //分页中显示总的数据
        showQuickJumper: false, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("realdata.a24"),
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          align: "center",
          width: 130,
        },
        {
          title: this.$t("realdata.a26"),
          dataIndex: "cardNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardNo");
          },
          align: "center",
          width: 130,
        },
        {
          title: this.$t("realdata.a28"),
          dataIndex: "managementCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managementCode");
          },
          align: "center",
          width: 130,
        },
        {
          title: this.$t("realdata.a22"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          align: "center",
          width: 130,
        },
        {
          title: this.$t("realdata.a33"),
          dataIndex: "personGrName",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName");
          },
          align: "center",
        },
      ],
    };
  },
  watch: {
    // 监听月报和日报选择，改变对应结束日期和输出日数
    Dateselection: {
      handler: function (newValue, oldValue) {
        if (newValue == 0) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (newValue == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听开始日期选择，改变对应结束日期和输出日数
    date: {
      handler: function (newValue, oldValue) {
        const date = this.Dateselection;
        if (date == 0) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(1, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else if (date == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
          const year = moment(date).format("YYYY");
          const month = moment(date).format("MM");
          const days = new Date(year, month, 1);
          this.days = new Date(days.getTime() - 864e5).getDate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听输出日数，改变结束日期
    day: {
      handler: function (newValue, oldValue) {
        if (this.Dateselection == 1) {
          const _date = JSON.stringify(this.date);
          const date = JSON.parse(_date);
          this.date1 = moment(date)
            .add(this.day - 1, "day")
            .format("YYYY-MM-DD");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    disabled: function () {
      const length = this.selectedRowKeys.length;
      if (length == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      let height = this.$refs.main.clientHeight;
      let tableHeight = height - 200;
      this.size.y = tableHeight;
    },
    // 文件输出
    output() {
      // console.log(this.date);
      if (this.date == null) {
        this.$error({
          title: this.$t("realdata.a34"),
          content: this.$t("realdata.a35"),
          centered: true,
        });
      } else {
        this.loading = true;
        this.filendownload = false;
        const body = {
          dataType: this.dataType,
          endDate: moment(this.date1, "YYYY年MM月DD日").format("YYYY-MM-DD"),
          fileCount: this.fileValue,
          ids: this.selectedRowKeys,
          monthlyOrDaily: this.Dateselection,
          onlyCardHistory: this.onlyCardHistory,
          startDate: this.date.format("YYYY-MM-DD"),
        };
        const query = {
          siteId: this.$route.query.id,
        };
        // console.log(body);
        dataDownload(body, query)
          .then((res) => {
            // console.log(res);
            this.loading = false;
            this.filendownload = true;
            this.filename = decodeURIComponent(
              res.headers["content-disposition"]
            )
              .split(";")[1]
              .split("=")[1];
            this.file = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    // 文件下载
    download() {
      // console.log(this.filename);
      // console.log(this.file);
      let url = window.URL.createObjectURL(this.file);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);
      link.click();
    },
    // 卡列表查询
    inquire() {
      this.mainLoading = true;
      const body = {
        cardFaceNo: this.cardFaceNo,
        cardNo: this.cardNo,
        managementCode: this.managementCode,
        personName: this.personName,
      };
      const query = {
        siteId: this.$route.query.id,
      };
      getAllUseCardList(body, query)
        .then((res) => {
          // console.log(res);
          this.mainLoading = false;
          const { data } = res;
          this.tableData = data;
        })
        .catch((err) => {
          console.log(err);
          this.mainLoading = false;
        });
    },
    // 卡勾选的方法
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      // console.log(selectedRowKeys);
    },
  },
  components: {
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#card {
  height: 100%;
  padding: 10px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-top: none;
  overflow: hidden;
}
.header {
  display: flex;
  height: 50px;
  min-width: 900px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header_left_bottom {
  display: flex;
  width: 650px;
  font-size: 14px;
}
.header_left_bottom_date {
  margin: 0px 30px;
}
.header_left_bottom_right {
  position: relative;
  width: 170px;
  height: 32px;
}
.main {
  height: calc(100% - 90px);
  overflow: hidden;
}
.main_header {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 10px 0px;
}
.main_header_condition {
  display: flex;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.7);
}
.main_header_condition span {
  margin-right: 15px;
}
.floor {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.floor_left {
  display: flex;
}
.floor_title {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
  background: #7682ce;
}
.floor_download {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;
}
.floor_output {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  font-size: 15px;
  margin-left: 15px;
}
.floor_download >>> .anticon {
  margin-left: 15px;
  font-size: 24px;
}
.floor_download >>> .anticon:hover {
  color: #1f6ce0;
}
.loading {
  width: 100px;
}
.file {
  width: 200px;
  position: absolute;
}
.interval {
  font-size: 20px;
  margin: 0px 10px;
}
.inquire_explain {
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>